import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { login } from "../actions/authActions";
import { MDBInput, MDBAlert, MDBBtn, MDBRow, MDBCol } from "mdbreact";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/admin");
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const user = {
      username,
      password,
    };
    this.props.login(user);
  };

  render() {
    const { auth } = this.props;
    let response = "";
    if (auth.message) {
      let message = auth.message;
      response = (
        <MDBRow className="py-3 justify-content-center">
          <MDBCol sm={12} md={4}>
            <MDBAlert color="danger">{message}</MDBAlert>
          </MDBCol>
        </MDBRow>
      );
    }
    return (
      <Fragment>
        <MDBRow className="py-3 justify-content-center">
          <MDBCol sm={12} md={4}>
            <form onSubmit={this.onSubmit}>
              <div className="grey-text">
                <MDBInput
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                  label="Username"
                  icon="user"
                  type="text"
                  required
                />
                <MDBInput
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  label="Password"
                  icon="lock"
                  type="password"
                  required
                />
              </div>
              <div className="text-center">
                <MDBBtn type="submit" className="custom-btn">
                  Login
                </MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
        {response}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
