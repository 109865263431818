import React, { Component } from "react";
import { MDBBtn, MDBInput } from "mdbreact";
import { connect } from "react-redux";
import { sendToClient } from "../../actions/noteActions";

class ClientSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientEmail: "",
      clientSubject: "",
      clientMessage: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let baseurl = window.location.origin;
    this.setState({
      clientSubject: `Login credentials for: ${this.props.siteurl}`,
      clientMessage: `View your new username & password at: \n ${baseurl}/read/${this.props.id} \n\n Contact your TXC Account Manager for the PIN`
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteurl !== prevProps.siteurl) {
      let baseurl = window.location.origin;
      this.setState({
        clientSubject: `Login credentials for: ${this.props.siteurl}`,
        clientMessage: `View your new username & password at: \n ${baseurl}/read/${this.props.id} \n\n Contact your TXC Account Manager for the PIN`
      });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const emailData = {
      clientEmail: this.state.clientEmail,
      clientSubject: this.state.clientSubject,
      clientMessage: this.state.clientMessage
    }; 
    this.props.sendToClient(emailData);
    this.setState({
      clientEmail: "",
      clientSubject: "",
      clientMessage: ""
    });
  };

  render() {
    return (
      <React.Fragment>
        <form className="client-send" onSubmit={this.onSubmit}>
          <p className="h4 text-center mb-4">Send to Client</p>
          <div className="grey-text">
            <MDBInput
              name="clientEmail"
              value={this.state.clientEmail}
              onChange={this.onChange}
              label="Client Email"
              icon="envelope"
              type="email"
              required
            />
            <MDBInput
              name="clientSubject"
              value={this.state.clientSubject}
              onChange={this.onChange}
              label="Subject"
              icon="tag"
              type="text"
              required
            />
            <MDBInput
              name="clientMessage"
              value={this.state.clientMessage}
              onChange={this.onChange}
              label="Message to client"
              icon="pencil-alt"
              type="textarea"
              rows="5"
              required
            />
          </div>
          <div className="text-center">
            <MDBBtn type="submit" className="custom-btn">
              Send
            </MDBBtn>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { sendToClient }
)(ClientSend);
