import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBAlert } from 'mdbreact';
import { connect } from 'react-redux';
import { addNote } from '../../actions/noteActions';

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteurl: '',
      username: '',
      password: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    let note = {
      siteurl: this.state.siteurl,
      username: this.state.username,
      password: this.state.password,
      userType: 'Client',
    };
    this.props.addNote(note);
    this.setState({
      siteurl: '',
      username: '',
      password: '',
    });
  };

  render() {
    const { note } = this.props;
    let response = '';
    if (note.message) {
      let message = note.message;
      response = <MDBAlert color="primary">{message}</MDBAlert>;
    }
    return (
      <>
        <MDBRow className="pt-3 justify-content-center text-center">
          <MDBCol sm={12} md={8}>
            <h4>
              Welcome to our secure client password sharing tool.
              <br />
              Please send your credentials to Texas Creative below.
            </h4>
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <MDBCol sm={12} md={6}>
            <form onSubmit={this.onSubmit}>
              <div className="grey-text">
                <MDBInput
                  name="siteurl"
                  value={this.state.siteurl}
                  onChange={this.onChange}
                  label="Site URL or IP Address"
                  icon="globe-americas"
                  type="text"
                  required
                />
                <MDBInput
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                  label="Username"
                  icon="user"
                  type="text"
                  required
                />
                <MDBInput
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  label="Password"
                  icon="lock"
                  type="text"
                  required
                />
              </div>
              <p className="disclaimer">
                Your credentials are only stored in this system long enough for
                us to copy them into our enterprise{' '}
                <a
                  href="https://lastpass.com"
                  className="txc-red"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LastPass Password Manager
                </a>{' '}
                account.
                <br />
                By submitting this form you agree to Texas Creative's use &
                storage of these credentials on your behalf. You may request
                permanent deletion of your stored credentials by contacting your
                TXC account manager.
              </p>
              <div className="text-center">
                <MDBBtn type="submit" className="custom-btn">
                  Send
                </MDBBtn>
              </div>
            </form>
            {response}
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, { addNote })(Create);
