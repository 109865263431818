import {
  NOTES_LOADING,
  GET_NOTES,
  ADD_NOTE,
  READ_NOTE,
  DELETE_NOTE,
  GET_NOTE,
  CLEAR_NOTE,
  SEND_EMAIL
} from "../actions/types";

const initialState = {
  notes: [],
  note: {},
  message: "",
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NOTES_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_NOTE:
      return {
        ...state,
        note: {}
      };
    case ADD_NOTE:
      return {
        ...state,
        ...action.payload
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload.Notes,
        loading: false
      };
    case GET_NOTE:
      return {
        ...state,
        note: { ...action.payload }
      };
    case READ_NOTE:
      return {
        ...state,
        note: action.payload
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.map(item => {
          if (item.id !== action.payload.id) {
            return item;
          } else {
            return {
              ...action.payload
            };
          }
        })
      };
    case SEND_EMAIL:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
