import axios from "axios";
import {
  NOTES_LOADING,
  GET_NOTES,
  ADD_NOTE,
  READ_NOTE,
  DELETE_NOTE,
  GET_NOTE,
  CLEAR_NOTE,
  SEND_EMAIL
} from "./types";

var MY_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
var slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);

export const getNotes = () => dispatch => {
  dispatch(setNotesLoading());
  axios
    .get("/api/note")
    .then(res =>
      dispatch({
        type: GET_NOTES,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
};

export const getNote = id => dispatch => {
  dispatch(setNotesLoading());
  axios
    .get(`/api/note/${id}`)
    .then(res =>
      dispatch({
        type: GET_NOTE,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
};

export const addNote = item => dispatch => {
  axios
    .post("/api/note/create", item)
    .then(res =>
      dispatch({
        type: ADD_NOTE,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
  if (item.userType === "Client") {
    slack.send({
      channel: "coders",
      icon_url: "http://example.com/my-icon.png",
      text: `<!here> New client credentials created for ${item.siteurl} - <https://secure.texascreative.com/login|View Now>`,
      username: "TXCreds"
    });
  }
};

// CLIENT
export const readNote = noteData => dispatch => {
  const pin = noteData.pin;
  axios
    .post(`/api/note/read/${noteData.url}`, { pin })
    .then(res =>
      dispatch({
        type: READ_NOTE,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
};

// ADMIN FUNCTIONS
export const deleteNote = id => dispatch => {
  axios
    .delete(`/api/note/delete/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_NOTE,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
};

export const setNotesLoading = () => {
  return {
    type: NOTES_LOADING
  };
};

export const clearNote = () => {
  return {
    type: CLEAR_NOTE
  };
};

// EMAILING
export const sendToClient = mail => dispatch => {
  axios
    .post("/api/mail", mail)
    .then(res =>
      dispatch({
        type: SEND_EMAIL,
        payload: res.data
      })
    )
    .catch(err => console.log("error ", err));
};
