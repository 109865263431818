import React, { Component } from "react";
import { MDBBtn, MDBInput } from "mdbreact";
import { connect } from "react-redux";
import { getNote, readNote } from "../../actions/noteActions";

class AdminCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyUrl: "Copy",
      copyUser: "Copy",
      copyPass: "Copy",
      siteurl: "",
      username: "",
      password: "",
      loaded: false
    };

    this.copyUrl = this.copyUrl.bind(this);
    this.copyUsername = this.copyUsername.bind(this);
    this.copyPassword = this.copyPassword.bind(this);
  }

  copyUrl() {
    let siteurl = document.getElementById("CopyUrl");
    siteurl.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copied!", copyUser: "Copy", copyPass: "Copy" });
  }

  copyUsername() {
    let userName = document.getElementById("CopyUser");
    userName.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copy", copyUser: "Copied!", copyPass: "Copy" });
  }

  copyPassword() {
    let password = document.getElementById("CopyPass");
    password.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copy", copyUser: "Copy", copyPass: "Copied!" });
  }

  componentWillUpdate(prevProps) {
    if (this.state.loaded === false) {
      if (prevProps.note) {
        this.setState({
          siteurl: prevProps.note.siteurl,
          username: prevProps.note.creds.username,
          password: prevProps.note.creds.password,
          loaded: true
        });
      }
    }
  }

  render() {
    let { siteurl, username, password } = this.state;
    return (
      <React.Fragment>
        <form>
          <div className="grey-text">
            <div className="input-group copy-text">
              <MDBInput
                name="siteurl"
                value={siteurl}
                readOnly
                id="CopyUrl"
                label="Site URL or IP Address"
                icon="globe-americas"
                type="text"
              />
              <div className="input-group-append">
                <MDBBtn
                  onClick={this.copyUrl}
                  type="button"
                  className="custom-btn"
                >
                  {this.state.copyUrl}
                </MDBBtn>
              </div>
            </div>
          </div>
          <div className="grey-text">
            <div className="input-group copy-text">
              <MDBInput
                name="username"
                value={username}
                readOnly
                id="CopyUser"
                label="Username"
                icon="user"
                type="text"
              />
              <div className="input-group-append">
                <MDBBtn
                  onClick={this.copyUsername}
                  type="button"
                  className="custom-btn"
                >
                  {this.state.copyUser}
                </MDBBtn>
              </div>
            </div>
          </div>
          <div className="grey-text">
            <div className="input-group copy-text">
              <MDBInput
                name="password"
                value={password}
                readOnly
                id="CopyPass"
                label="Password"
                icon="lock"
                type="text"
              />
              <div className="input-group-append">
                <MDBBtn
                  onClick={this.copyPassword}
                  type="button"
                  className="custom-btn"
                >
                  {this.state.copyPass}
                </MDBBtn>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  note: state.note.note
});

export default connect(
  mapStateToProps,
  { getNote, readNote }
)(AdminCopy);
