import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getNotes,
  deleteNote,
  readNote,
  clearNote,
} from "../../actions/noteActions";
import {
  MDBBtn,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import AdminCopy from "../Admin/AdminCopy";

const clientColumns = [
  {
    label: "Site Title / URL",
    field: "siteurl",
    sort: "asc",
  },
  {
    label: "Read",
    field: "read",
    sort: "asc",
  },
  {
    label: "Delete",
    field: "delete",
    sort: "asc",
  },
];
const adminColumns = [
  {
    label: "Site Title / URL",
    field: "siteurl",
    sort: "asc",
  },
  {
    label: "View Count",
    field: "views",
    sort: "asc",
  },
  {
    label: "PIN",
    field: "pin",
    sort: "asc",
  },
  {
    label: "URL",
    field: "copy",
    sort: "asc",
  },
  {
    label: "Delete",
    field: "delete",
    sort: "asc",
  },
];

class NoteList extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      siteurl: "",
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    this.props.getNotes();
  }

  toggle = (url) => {
    const noteData = {
      url,
      pin: null,
    };
    if (this.state.modal === false) {
      this.props.readNote(noteData);
    } else if (this.state.modal === true) {
      setTimeout(() => {
        this.props.clearNote(noteData);
      }, 200);
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  onDelete = (id) => {
    this.props.deleteNote(id);
  };

  copyToClipboard = (id) => {
    let copyLink = "";
    copyLink = document.getElementById(id);
    copyLink.select();
    document.execCommand("copy");
  };

  render() {
    const { notes, loading } = this.props.note;
    let response,
      clientResponse,
      adminResponse = "";
    let clientList = [];
    let adminList = [];
    if (loading === true) {
      response = (
        <div className="spinner-grow text-warning" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else {
      if (notes.length > 0) {
        notes.map((note) => {
          if (note.userType === "Client") {
            if (note.creds) {
              clientList.push({
                siteurl: note.siteurl,
                read: (
                  <MDBBtn
                    onClick={this.toggle}
                    className="destroy-btn"
                    size="sm"
                  >
                    Read
                  </MDBBtn>
                ),
                delete: (
                  <MDBBtn className="destroy-btn" size="sm">
                    Delete
                  </MDBBtn>
                ),
              });
            } else {
              clientList.push({
                siteurl: note.siteurl,
                read: (
                  <MDBBtn
                    onClick={() => this.toggle(note.id)}
                    className="custom-btn"
                    size="sm"
                  >
                    Read
                  </MDBBtn>
                ),
                delete: (
                  <MDBBtn
                    onClick={() => this.onDelete(note.id)}
                    className="destroy-btn"
                    size="sm"
                  >
                    Delete
                  </MDBBtn>
                ),
              });
            }
          } else if (note.userType === "Admin") {
            let baseurl = window.location.origin;
            note.views = note.views.toString();
            adminList.push({
              siteurl: note.siteurl,
              views: note.views,
              pin: (
                <React.Fragment>
                  <MDBInput
                    type="text"
                    id={note.pin}
                    readOnly
                    value={note.pin}
                  />
                  <MDBBtn
                    onClick={() => this.copyToClipboard(note.pin)}
                    type="button"
                    className="custom-btn"
                    size="sm"
                  >
                    Copy PIN
                  </MDBBtn>
                </React.Fragment>
              ),
              copy: (
                <React.Fragment>
                  <MDBInput
                    type="text"
                    id={note.id}
                    readOnly
                    value={`${baseurl}/read/${note.id}`}
                  />
                  <MDBBtn
                    onClick={() => this.copyToClipboard(note.id)}
                    type="button"
                    className="custom-btn"
                    size="sm"
                  >
                    Copy URL
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <MDBBtn
                  onClick={() => this.onDelete(note.id)}
                  className="destroy-btn"
                  size="sm"
                >
                  Delete
                </MDBBtn>
              ),
            });
          }
          return null;
        });
      }
      if (clientList.length === 0) {
        clientResponse = <MDBAlert color="warning">No notes found</MDBAlert>;
      }
      if (adminList.length === 0) {
        adminResponse = <MDBAlert color="warning">No notes found</MDBAlert>;
      }
    }

    return (
      <React.Fragment>
        <MDBRow className="justify-content-center py-3">
          <MDBCol sm={12} md={10}>
            <h2 className="text-center">Client Created Credentials</h2>
            <MDBTable btn>
              <MDBTableHead columns={clientColumns} />
              <MDBTableBody rows={clientList} />
            </MDBTable>
            {response}
            {clientResponse}
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow className="justify-content-center py-3 admin-list">
          <MDBCol sm={12} md={10}>
            <h2 className="text-center">Admin Created Credentials</h2>
            <MDBTable btn>
              <MDBTableHead columns={adminColumns} />
              <MDBTableBody rows={adminList} />
            </MDBTable>
            {response}
            {adminResponse}
          </MDBCol>
        </MDBRow>
        {/* MODAL */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle} />
          <MDBModalBody>
            <AdminCopy />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn className="destroy-btn" onClick={this.toggle}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {/* END MODAL */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, {
  getNotes,
  deleteNote,
  readNote,
  clearNote,
})(NoteList);
