import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Provider } from "react-redux";
import store from "./store";
import Create from "./components/User/Create";
import Read from "./components/User/Read";
import Admin from "./components/Admin/Admin";
import Login from "./components/Login";
import "./App.scss";

import PrivateRoute from "./utils/PrivateRoute";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logout } from "./actions/authActions";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser({ decoded }));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = "/login";
  }
}

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <MDBContainer className="mt-5 pt-5">
            <MDBRow className="py-3 justify-content-center text-center">
              <MDBCol sm={12} md={6}>
                <Link to="/">
                  <img
                    className="w-100"
                    src={require("./texas-creative.png")}
                  />
                </Link>
              </MDBCol>
            </MDBRow>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/read/:id" component={Read} />
              <Route exact path="/" component={Create} />
            </Switch>
            <Switch>
              <PrivateRoute exact path="/admin" component={Admin} />
            </Switch>
          </MDBContainer>
        </Router>
      </Provider>
    );
  }
}
