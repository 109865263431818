import React, { Component } from "react";
import { MDBBtn, MDBInput, MDBAlert } from "mdbreact";
import ClientSend from "./ClientSend";
import { connect } from "react-redux";
import { addNote, getNotes } from "../../actions/noteActions";

class AdminCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteurl: "",
      username: "",
      password: "",
      pin: "",
      clientMailer: false,
      success: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // componentWillReceiveProps() {
  //   this.props.getNotes();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.note.message !== prevProps.note.message) {
      this.setState({
        clientMailer: false,
        success: this.props.note.message
      });
      if (this.props.note.message === "") {
        this.setState({
          clientMailer: true,
          success: ""
        });
      }
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit = e => {
    e.preventDefault();
    let note = {
      siteurl: this.state.siteurl,
      username: this.state.username,
      password: this.state.password,
      userType: "Admin",
      pin: this.state.pin,
      views: 0
    };
    this.props.addNote(note);
    this.setState({
      siteurl: "",
      username: "",
      password: "",
      pin: "",
      clientMailer: true
    });
  };

  render() {
    let { note } = this.props.note;
    const { clientMailer, success } = this.state;
    let mailer = "";
    let message = "";
    if (Object.keys(note).length > 0 && note.constructor === Object) {
      if (clientMailer) {
        mailer = <ClientSend siteurl={note.siteurl} id={note.id} />;
      }
      if (success !== "") {
        message = <MDBAlert color="primary"> {success}</MDBAlert>;
      }
    }
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="grey-text">
            <MDBInput
              name="siteurl"
              value={this.state.siteurl}
              onChange={this.onChange}
              label="Site URL or IP Address"
              icon="globe-americas"
              type="text"
              required
            />
            <MDBInput
              name="username"
              value={this.state.username}
              onChange={this.onChange}
              label="Username"
              icon="user"
              type="text"
              required
            />
            <MDBInput
              name="password"
              value={this.state.password}
              onChange={this.onChange}
              label="Password"
              icon="lock"
              type="text"
              required
            />
            <MDBInput
              name="pin"
              value={this.state.pin}
              onChange={this.onChange}
              label="pin"
              icon="fingerprint"
              type="text"
              required
            />
          </div>
          <div className="text-center">
            <MDBAlert color="warning">
              Remember this PIN - The client will need it to view their
              credentials
            </MDBAlert>
            <MDBBtn type="submit" className="custom-btn">
              Create
            </MDBBtn>
          </div>
        </form>
        {mailer}
        {message}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  note: state.note
});

export default connect(
  mapStateToProps,
  { addNote, getNotes }
)(AdminCreate);
