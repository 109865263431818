// AUTH/USER
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// NOTES/CREDS
export const GET_NOTES = "GET_NOTES";
export const GET_NOTE = "GET_NOTE";
export const ADD_NOTE = "ADD_NOTE";
export const READ_NOTE = "READ_NOTE";
export const ADMIN_ADD_NOTE = "ADMIN_ADD_NOTE";
export const NOTES_LOADING = "NOTES_LOADING";
export const DELETE_NOTE = "DELETE_NOTE";
export const CLEAR_NOTE = "CLEAR_NOTE";

// EMAIL
export const SEND_EMAIL = "SEND_EMAIL";