import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/authActions";
import { clearNote } from "../../actions/noteActions";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import NoteList from "./NoteList";
import AdminCreate from "../Admin/AdminCreate";

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    if (this.state.modal === false) {
      this.props.clearNote();
    }
  };

  onLogout = (e) => {
    this.props.logout();
  };

  render() {
    const { userType } = this.props.auth.user;
    let adminContent = "";
    if (userType === "admin") {
      adminContent = (
        <React.Fragment>
          <NoteList />
          <MDBRow className="justify-content-center">
            <MDBCol sm={12} md={6} className="text-center">
              <MDBBtn
                onClick={this.onLogout}
                type="submit"
                className="destroy-btn"
              >
                Logout
              </MDBBtn>
              {/* OPEN MODAL */}
              <MDBBtn
                onClick={this.toggle}
                type="submit"
                className="custom-btn admin-create"
              >
                Create Client Credentials
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {/* MODAL */}
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              Create Client Credentials
            </MDBModalHeader>
            <MDBModalBody>
              <AdminCreate />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn className="destroy-btn" onClick={this.toggle}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          {/* END MODAL */}
        </React.Fragment>
      );
    } else if (userType === "txc") {
      adminContent = (
        <div className="txc-user">
          <MDBRow className="justify-content-center">
            <MDBCol sm={12} md={6}>
              <AdminCreate />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="text-center">
              <MDBBtn
                onClick={this.onLogout}
                type="submit"
                className="logout mt-5 destroy-btn"
              >
                Logout
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      );
    }
    return <React.Fragment>{adminContent}</React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout, clearNote })(Admin);
