import React, { Component } from "react";
import { MDBBtn, MDBIcon, MDBAlert, MDBInput, MDBRow, MDBCol } from "mdbreact";
import { connect } from "react-redux";
import { getNote, readNote } from "../../actions/noteActions";

class Read extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyUrl: "Copy",
      copyUser: "Copy",
      copyPass: "Copy",
      pin: "",
    };

    this.copyUrl = this.copyUrl.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyUsername = this.copyUsername.bind(this);
    this.copyPassword = this.copyPassword.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getNote(id);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    let url = this.props.match.params.id;
    let pin = this.state.pin;
    const noteData = {
      url,
      pin,
    };
    this.props.readNote(noteData);
    this.setState({
      pin: "",
    });
  };

  copyUrl() {
    let siteUrl = document.getElementById("CopyUrl");
    siteUrl.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copied!", copyUser: "Copy", copyPass: "Copy" });
  }

  copyUsername() {
    let userName = document.getElementById("CopyUser");
    userName.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copy", copyUser: "Copied!", copyPass: "Copy" });
  }

  copyPassword() {
    let password = document.getElementById("CopyPass");
    password.select();
    document.execCommand("copy");
    this.setState({ copyUrl: "Copy", copyUser: "Copy", copyPass: "Copied!" });
  }

  render() {
    let { note } = this.props;
    let { readUrl, message, invalid, creds, siteurl } = note;
    let response,
      credentials,
      error = null;

    if (invalid) {
      error = (
        <MDBRow className="justify-content-center">
          <MDBCol sm={12} md={3} className="text-center">
            <MDBAlert color="danger">{invalid}</MDBAlert>
          </MDBCol>
        </MDBRow>
      );
    }

    if (note.userType === "Client") {
      response = (
        <MDBAlert color="danger">
          Sorry you're not allowed to view these
        </MDBAlert>
      );
    }

    // IF READ NOTE EXISTS - GET CREDENTIALS
    else if (readUrl) {
      response = (
        <React.Fragment>
          <form onSubmit={this.onSubmit}>
            <MDBRow className="justify-content-center">
              <MDBCol sm={12} md={3}>
                <div className="grey-text">
                  <div className="input-group copy-text">
                    <MDBInput
                      name="pin"
                      value={this.state.pin}
                      onChange={this.onChange}
                      label="Enter PIN"
                      icon="fingerprint"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-center py-4 text-center">
              <MDBCol sm={12} md={3}>
                <MDBBtn type="submit" className="destroy-btn">
                  <MDBIcon icon="eye" className="mr-2" />
                  {message}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          {error}
        </React.Fragment>
      );
    } else if (creds !== undefined) {
      credentials = (
        <React.Fragment>
          <p className="text-center">
            Please copy the following credentials to a secure place.
            <br />
            We recommend a free{" "}
            <a
              className="txc-red"
              href="https://lastpass.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              LastPass Password Manager
            </a>{" "}
            account.
          </p>
          <MDBRow className="py-5 justify-content-center">
            <MDBCol sm={12} md={5}>
              <form>
                <div className="grey-text">
                  <div className="input-group copy-text">
                    <MDBInput
                      name="siteurl"
                      value={siteurl}
                      readOnly
                      id="CopyUrl"
                      label="Site URL or IP Address"
                      icon="globe-americas"
                      type="text"
                    />
                    <div className="input-group-append">
                      <MDBBtn
                        onClick={this.copyUrl}
                        type="button"
                        className="custom-btn"
                      >
                        {this.state.copyUrl}
                      </MDBBtn>
                    </div>
                  </div>
                </div>
                <div className="grey-text">
                  <div className="input-group copy-text">
                    <MDBInput
                      name="username"
                      value={creds.username}
                      readOnly
                      id="CopyUser"
                      label="Username"
                      icon="user"
                      type="text"
                    />
                    <div className="input-group-append">
                      <MDBBtn
                        onClick={this.copyUsername}
                        type="button"
                        className="custom-btn"
                      >
                        {this.state.copyUser}
                      </MDBBtn>
                    </div>
                  </div>
                </div>
                <div className="grey-text">
                  <div className="input-group copy-text">
                    <MDBInput
                      name="password"
                      value={creds.password}
                      readOnly
                      id="CopyPass"
                      label="Password"
                      icon="lock"
                      type="text"
                    />
                    <div className="input-group-append">
                      <MDBBtn
                        onClick={this.copyPassword}
                        type="button"
                        className="custom-btn"
                      >
                        {this.state.copyPass}
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        </React.Fragment>
      );
      response = (
        <MDBRow className="justify-content-center">
          <MDBCol sm={12} md={6} className="text-center">
            <MDBAlert color="warning">{message}</MDBAlert>
          </MDBCol>
        </MDBRow>
      );
    } else {
      response = (
        <MDBRow className="justify-content-center">
          <MDBCol sm={12} md={4} className="text-center">
            <MDBAlert color="warning">{message}</MDBAlert>
          </MDBCol>
        </MDBRow>
      );
    }
    return (
      <div className="read-page">
        {credentials}
        {response}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  note: state.note.note,
});

export default connect(mapStateToProps, { getNote, readNote })(Read);
